<template>
  <b-card>
    <div class="text-right mb-1">
      <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
    </div>
    <ValidationObserver ref="updateFees" v-if="selectedFees"  v-slot="{ invalid }" >
      <b-form @submit.prevent="update">
        <b-row>
          <b-col md="3">
            <validation-provider name="tipo Busqueda" rules="required">
              <b-form-group label="Pool de renta">
                <b-form-select v-model="selectedFees.rentalPool" placeholder="Pool">
                  <option selected value="">Seleccione un Pool de renta</option>
                  <option
                    v-for="condo in rentalPoolFilter"
                    :key="condo.id"
                    :value="condo.id"
                  >
                    {{ condo.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider name="tasas" rules="required">
              <b-form-group label="Tasas">
                <b-form-select v-model="selectedFees.fees" placeholder="tasas">
                  <option selected value="">Seleccione una tasa</option>
                  <option
                    v-for="feesTypeItem in feesType"
                    :key="feesTypeItem.id"
                    :value="feesTypeItem.id"
                  >
                    {{ feesTypeItem.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider name="tipo de valor" rules="required">
              <b-form-group label="Tipo de valor">
                <b-form-select v-model="selectedFees.valueType" >
                  <option selected value="">Seleccione tipo de valor</option>
                  <option
                    v-for="valueTypeItem in valueTypeList"
                    :key="valueTypeItem.id"
                    :value="valueTypeItem.id"
                  >
                    {{ valueTypeItem.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider name="estimateType" rules="required">
              <b-form-group label="Tipo de Calculo">
                <b-form-select v-model="selectedFees.estimateType" >
                  <option selected value="">Seleccione tipo de calculo</option>
                  <option
                    v-for="estimateTypeItem in estimateTypeList"
                    :key="estimateTypeItem.id"
                    :value="estimateTypeItem.id"
                  >
                    {{ estimateTypeItem.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <b-form-group label="Año">
              <b-form-select v-model="selectedFees.year" :options="validYears" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Mes">
              <b-form-select v-model="selectedFees.month" :options="monthList" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <ValidationProvider rules="required" name="Valor">
              <b-form-group label="Valor" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  type="text"
                  v-model="selectedFees.value"
                  :state="errors[0] ? false : (valid ? true : null)"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <div class="float-left pt-1">
              <b-form-checkbox
                v-model="selectedFees.isActive"
                :value="true"
                class="custom-control-success"
              >Activo
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-can="'fivesclub_profit_update_fee'">
            <div class="float-right">
              <b-button
                type="submit"
                class="btn-block"
                variant="primary"
                :disabled="invalid || isSavingBlackout"
              > <b-spinner small v-if="isSavingBlackout"/> Guardar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { isANumber, showAlertMessage } from '@/helpers/helpers'

import { estimateTypeList,valueTypeList, monthList} from "@/data/data";

export default {

  data() {
    return {
      isActive: true,
      valueTypeList,
      estimateTypeList,
      idFeeConfig: this.$route.params.id,
      isSavingBlackout: false,
      datenow: new Date(),
      year: new Date().getFullYear(),
      month: 1,
      monthList
    }
  },
  components: {

  },
  computed: {
    ...mapState('auth',['user']),
    ...mapState("fivesClubProfit", ['rentalPoolFilter','feesType','selectedFees']),
    validYears() {
      return [this.datenow.getFullYear(), this.datenow.getFullYear() + 1];
    },
  },
  methods: {
    ...mapMutations('fivesClubProfit', ['setFees', 'setSelectedFees']),
    ...mapActions('fivesClubProfit', ['updateFees','fetchFeeSettings']),
    async update(){
      this.isSavingBlackout = true
      const payload = {
        id: this.selectedFees.id,
        month: this.selectedFees.month,
        year: this.selectedFees.year,
        createdBy: this.user.idUser,
        fees:this.selectedFees.fees,
        rentalPool : this.selectedFees.rentalPool,
        valueType:this.selectedFees.valueType,
        estimateType:this.selectedFees.estimateType,
        value:this.selectedFees.value,
        isActive: Boolean(this.selectedFees.isActive)
      }

      const { status, message } = await this.updateFees( payload ) // saving in backend

      if(status){
        showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
        //vuelvo a pedir la info del back
        this.goToHome({reload: true})
      }

      this.isSavingBlackout = false
    },
    goToHome(params){
      this.$router.push({ name: 'fees-list', params })
    },
  },
};
</script>
<style scoped>
.custom-control {
  margin-block-start: 1.5rem;
}
.btn-add{
  margin-block-start: 1.5rem;
}
.btn-borrar{
  margin-block-start: 1.5rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>